import React from 'react';
import axios from 'axios';
import $ from 'jquery';

class FetchDemo extends React.Component {
  state = {
    posts: []
  }

  componentDidMount() {
    $(document).ready(function() {
      function run(){
        const $elem = $('.c');
        let cnt = $elem.attr('id');

        $elem.text(cnt);

        setInterval(function(){
          cnt--;

          if(cnt >= 0){
            $elem.text(cnt);
          }

          if(cnt === 0){
            $elem.text(cnt);
          }
        },1000);
      }
      // Start
      run();
      // Loop
      setInterval(function(){
        run();
      },60000);
    });

    function timingLoad() {
      axios.get(`https://beacon.nist.gov/beacon/2.0/pulse/last`)
        .then(data => {
          const nistsig = JSON.stringify(data.data.pulse.signatureValue);

          $('#nistresult').html(JSON.parse(nistsig));
        });
    }

    timingLoad();
    setInterval(timingLoad, 60000);
  }

  render() {
    return (
      <div id="nistresult"/>
    );
  }
}

export default FetchDemo;