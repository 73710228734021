import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'gatsby';

import Nist from '../components/nisttest';
import Layout from '../components/layout';

import icontimestamp from '../img/2x/icon-timestamp.png';
import iconencryptedw from '../img/2x/icon-encrypted-w.png';
import undraw_time_management_30iu from '../img/undraw_time_management_30iu.svg';
import undraw_prototyping_process_rswj from '../img/undraw_prototyping_process_rswj.svg';

import AniEncrypt from '../components/Animations/AniEncrypt';
import AniTimestamp from '../components/Animations/AniTimestamp';
import AniNetwork from '../components/Animations/AniNetwork';
import AniTransaction from '../components/Animations/AniTransaction';

const Beacon = () => (
  <Layout>
    <div className="skipScroll">
      <Helmet
        title={'NIST Beacon Random Number Generator | Quantum Source of Randomness'}
        meta={[
          {
            name: 'description',
            content: 'The NIST Randomness Beacon is a US Government technology, providing Quantum sources of randomness to improve cybersecurity.'
          }
        ]}
      />
      <div className="hd-space skipScroll"/>
      <div className="page skipScroll">
        <div className="endscroll" id="endscroll"/>
        <section className="skipScroll bg-blue-3 pb-0">
          <video id="video-background" loop="loop" playsInline="playsinline" autoPlay="autoPlay" muted="muted">
            <source src={require('../img/video/beacon.mp4')} type="video/mp4"/>
          </video>
          <div className="blueblue video-overlay"/>
          <div className="wow3 video-overlay"/>
          <div className="container mb-5 pb-5 text-white">
            <div className="row">
              <div className="col-12 col-md-10 col-lg-7">
                <h6 className="display-upper-1X line red text-greyX mb-4" data-aos="fade-up" data-aos-duration="500" data-aos-delay="200">
                  Nist Beacon
                </h6>
                <h2 className="display-1  pb-3 " data-aos="fade-up" data-aos-duration="500" data-aos-delay="400">

                  Unpredictable, truly randomly generated number sequences
                </h2>
                <a href="#how" className="btn btn-outline-primary scroll mb-5" data-aos="fade-up" data-aos-duration="500" data-aos-delay="600">
                  How it works
                </a>
              </div>
              <div className="col-12 col-md-10 col-lg-5">
                <p className="lead mt-5 pt-2" data-aos="fade-left" data-aos-duration="300" data-aos-delay="800">
                  The beacon uses quantum effects to generate a sequence of truly random values, guaranteed to be unpredictable, even if an attacker has access to the random source.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-trans-3 mt-5 pt-0 pb-5 text-center rel dark-cards ">
            <div className="">
              <div className="container">
                <div className="row text-dark " data-aos="fade-in" data-aos-duration="800">
                  <div className="col col-12 col-sm-12 col-md-12  mx-auto mtm-5">
                    <div className="row">
                      <div className="col col-12 col-sm-12 col-md-6 col-lg-3 mb-3" data-aos="fade-up" data-aos-duration="600">
                        <div className="card card-body">
                          {/* <img src={iconbeacon} className="" height="64px" width="64px" /> */}
                          <AniNetwork/>
                          <h4 className="card-title">Unpredictability</h4>
                          <p className="card-text">
                            Making it impossible for keys to be algorithmically predicted.
                          </p>
                        </div>
                      </div>
                      <div className="col col-12 col-sm-12 col-md-6 col-lg-3 mb-3" data-aos="fade-up" data-aos-duration="800">
                        <div className="card card-body">
                          {/* <img src={icontimestamp} className="" height="64px" width="64px" /> */}
                          <AniTimestamp/>
                          <h4 className="card-title">Autonomy</h4>
                          <p className="card-text">
                            The distribution of random bits cannot be altered by 3rd parties.
                          </p>
                        </div>
                      </div>
                      <div className="col col-12 col-sm-12 col-md-6 col-lg-3 mb-3" data-aos="fade-up" data-aos-duration="1000">
                        <div className="card card-body">
                          {/* <img src={iconblockdata} className="" height="64px" width="64px" /> */}
                          <AniTransaction/>
                          <h4 className="card-title">Consistency</h4>
                          <p className="card-text">
                            All users can be confident they receive the same random string.
                          </p>
                        </div>
                      </div>
                      <div className="col col-12 col-sm-12 col-md-6 col-lg-3 mb-3" data-aos="fade-up" data-aos-duration="1200">
                        <div className="card card-body">
                          {/* <img src={iconsecurity} className="" height="64px" width="64px" /> */}
                          <AniEncrypt/> {/* <div className="ani"><Ani /></div> */}
                          <h4 className="card-title">Security</h4>
                          <p className="card-text">
                            New unpredictable numbers are generated every 60 seconds.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-light-grey text-dark pt-0 pb-5 text-center rel" id="nist">
          <div className="container">
            <div className="row">
              <div className="col col-12 col-sm-12 col-md-6 mx-auto" data-aos="fade-up" data-aos-duration="600">
                {/*
                  <h1 className="display-3 font-weight-normalX pb-3 headlinXe mb-1" data-aos="fade-up" data-aos-duration="500" data-aos-delay="300"><img src={nist1} className="float-left mr-3 mt-1" width="100px" /> Beacon</h1>
    */
                }
                <h3 className="lead font-weight-bold pt-4 mt-4">
                  NIST generates an autonomous, unpredictable, consistent, 512-bit, full-entropy random number every 60 seconds.
                </h3>
                {/*
                          <p className="border-topX pb-0 mb-0 text-greyX smallX ">Quantum sourced, truly random values, critical in all cryptographic protocols.</p>
    */
                }
              </div>
            </div>
            <div className="row">
              <div className="col col-12 col-sm-12 col-md-8 mx-auto mt-3" data-aos="fade-up" data-aos-duration="600">
                {/*
            <Nist />
            */  }


                <Nist />

                <div className="mt-2 text-grey small">

                  Pulse regenerates in <strong><span className="c" id="60"/> seconds.</strong>

                </div>
              </div>
            </div>
          </div>
        </section>
        }
        <section id="secure" className="text-whiteX skipScroll bg-blue-3X">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-6  py-3">
                <h3 className="display-2 font-weight-bold mb-5 pb-3 headline red">
                  Truly random for maximum security strength
                </h3>
                <p>
                  In cryptography, the quality of the random numbers used directly determines the security strength of the system. The quality of the random number generator influences how difficult it is to break certain security assumptions of the system.
                </p>
                <p>
                  By generating random numbers through a process that is based entirely on quantum mechanics, the NIST Randomness Beacon is able to generate a value that is truly random to a degree that has never before been achieved in computing.
                </p>
                <p>
                  <strong>
                    In short, good random numbers are fundamental to almost all secure computer systems.
                  </strong>{' '}
                </p>
              </div>
              <div className="col col-12 col-sm-12 col-md-12 col-lg-6 text-dark plain-cards ">
                <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
                  <ol className="carousel-indicators">
                    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"/>
                    <li data-target="#carouselExampleIndicators" data-slide-to="1"/>
                  </ol>
                  <div className="carousel-inner">
                    <div className="carousel-item active  card-body">
                      <div className="row">
                        <div className="col col-12 col-sm-12 col-md-12 mb-3 text-center" data-aos="fade-up" data-aos-duration="600">
                          <div className="card card-body py-5">
                            <div className="choose-wrapper">
                              <div className="">
                                <div className="text-center">
                                  <img src={icontimestamp} className="" height="96px" width="96px" alt=""/>
                                  <h3 className="display-4 font-weight-bold mb-4">
                                    Full-entropy with quantum mechanics
                                  </h3>
                                  <p>
                                    Quantum mechanics provides a <strong>
                                    superior source of randomness
                                  </strong> because measurements of some quantum particles (those in a “superposition” of both 0 and 1 at the same time) have fundamentally unpredictable results.{' '}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item  card-body X">
                      <div className="row">
                        <div className="col col-12 col-sm-12 col-md-12 mb-3 text-center" data-aos="fade-up" data-aos-duration="800">
                          <div className="card card-body py-5">
                            <div className="choose-wrapper pl-5X">
                              <div className="">
                                <div className="text-center">
                                  <img src={iconencryptedw} className="" height="96px" width="96px" alt=""/>
                                  <h3 className="display-4 font-weight-bold mb-4">
                                    The NIST beacon certifies randomness
                                  </h3>
                                  <p>
                                    In the NIST beacon, the final numbers are certified to be random even if the measurement settings and seed are publicly known; the only requirement is that the  <a href="https://en.wikipedia.org/wiki/Bell%27s_theorem" target="_blank" rel="external noopener noreferrer" className="text-red">
                                    Bell test experiment
                                  </a> be physically isolated from users and hackers.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"/>
                    <span className="sr-only">Previous</span>
                  </a>
                  <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"/>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*

    <span dangerouslySetInnerHTML={{__html: '<div class="top-circles bottom"><span><div classname="footer-shapes"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 320 17"><g><path style="opacity:1;fill: #0066ff;" d="M0,0C52.9,1.2,104.7,7,155,16.8H0V0L0,0z"></path><path style="opacity:1;fill: #0066ff;" d="M320,2.6c-51.6,9.1-104.6,13.8-158.8,13.8c-2.9,0-5.9,0.1-8.8,0.4H320V2.6z"></path><g><path id="a" style="fill: #85ffde;" d="M0,14.8C51,5.9,103.5,1.3,157.1,1.3c55.6,0,110,5,162.9,14.5v1H0C0,16.8,0,14.8,0,14.8z"></path></g></g></svg></div></span></div>'}} />
*/
        }
        <section id="how" className="bg-blue-3 choose-us skipScroll ">
          <video id="video-background" loop="loop" autoPlay="autoPlay" playsInline="playsinline" muted="muted">
            <source src={require('../img/video/lighthouse.mp4')} type="video/mp4"/>
          </video>
          <div className="beacon-video video-overlay"/>
          <div className="container text-white">
            <div className="row">
              <div className="col-lg-7 ml-auto">
                <div className="choose-wrapper">
                  <div className="choose-inner">
                    <div className="choose-text">
                      <h3 className="display-3 font-weight-bold headline  pb-3 mb-4">
                        How the NIST beacon works
                      </h3>
                      <p className="lead">
                        The beacon generates the digital bits using photons, or particles of light, rather than the current CPU and Intel chip processes. An experiment previously conducted at NIST in 2015, proved that the quantum entanglement effect referred to by Einstein as 'Spooky Action at a Distance' is real.
                      </p>
                      <hr/>
                      <p className="larger">
                        This beacon processes the spooky output to certify and quantify the randomness available in the data and generate a string of much more random bits thus allowing a value that is truly random to a degree that has never before been able to achieve in computing.
                      </p>
                      <p className="larger">
                        In contrast, random numbers generated using current technics are not certifiably random in an absolute sense. This is because they are generated by software formulae or physical devices whose supposedly random output can be undermined by factors such as{' '}
                        <strong>predictable sources of noise</strong>.
                      </p>
                      <a href="#first" className="btn btn-outline-light scroll">
                        Our model
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-0 text-center"/>
            </div>
          </div>
        </section>



        <section id="first" className=" choose-us skipScroll text-dark  bg-white">
          {/*
                    <div className="beaconback"></div>

                    <video autoPlay loop id="video-background" muted>
                    <source src="https://ak1.picdn.net/shutterstock/videos/3062101/preview/stock-footage-blue-stripe-light-lines-and-laser-weapon-ecg-cardiac-arrest-heart-stop-medical-video.webm" type="video/mp4"></source>
                    </video>
                    <div className="darkest video-overlay"></div>
      */
          }
          <div className="container">
            <div className="row">
              <div className="col-lg-7 mx-autoX">
                <div className="choose-wrapper">
                  <div className="choose-inner">
                    <div className="choose-text">
                      <h3 className="display-2 upper font-weight-bold mb-4 pb-3 headline red">
                        A World's First
                      </h3>
                      <h3 className="display-3 font-weight-bold mb-4 headlineX redX">
                        Temporal is the first cryptographic protocol to commercially deploy a successful use of the NIST beacon
                      </h3>
                      <p className="lead">
                        In 2016, a major cryptocurrency exchange was found to be using a source that could be predicted greater than random to generate public private key pairs, the result allowed hackers to compute the matching private key for a given public key and steal the users’ Bitcoins from the exchange.
                      </p>
                      <p>
                        This also demonstrated the pseudo-randomness which is inherent in Bitcoin and all current cryptocurrencies whereby the numbers generated appear to be random but are not certifiably random, meaning if an attacker could guess the generation, they would be able to generate all past and future keys.
                      </p>
                      <p>
                        We heavily utilize the NIST randomness beacon in our algorithm of ensuring consensus and using it as a timestamp in block generation and we are the first cryptographic protocol to commercially deploy a successful use of the NIST beacon.
                      </p>
                      <a href="#whyrandomness" className="btn btn-outline-secondary scroll">
                        How it works
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4  mx-auto text-center">
                <img src={undraw_prototyping_process_rswj} className="mt-5 pt-5 oppimg" alt=""/>
              </div>
            </div>
          </div>
        </section>
        <section id="whyrandomness" className=" choose-us skipScroll text-white  bg-dark">
          {/*
              <div className="beaconback"></div>

              <video autoPlay loop id="video-background" muted>
              <source src="https://ak1.picdn.net/shutterstock/videos/3062101/preview/stock-footage-blue-stripe-light-lines-and-laser-weapon-ecg-cardiac-arrest-heart-stop-medical-video.webm" type="video/mp4"></source>
              </video>
              <div className="darkest video-overlay"></div>
*/
          }
          <div className="container">
            <div className="row">
              <div className="col-lg-4   text-center">
                <img src={undraw_time_management_30iu} className="mt-5 oppimgX" alt=""/>
              </div>
              <div className="col-lg-7 mx-auto">
                <div className="choose-wrapper">
                  <div className="choose-inner">
                    <div className="choose-text">
                      <h3 className="display-3 font-weight-bold mb-4 pb-3 headline red">
                        Temporal uses timestamped randomness, making it impossible to pre-compute the timestamp value
                      </h3>
                      <h4 className="display-4 font-weight-boldX mb-4 pb-3">
                        The degree of randomness from the NIST beacon dramatically increases the cost to attack the network by orders of magnitude greater than current cryptocurrencies.
                      </h4>
                      <p>
                        While it is common for a standard UNIX timestamp which is defined as the number of seconds that have elapsed since 00:00:00 Coordinated Universal Time (UTC), Thursday, 1 January 1970 in the header of a block in the blockchain, we have chosen not to use this, since this value is very easily pre-computed. The ability to pre-compute the timestamp could allow an attacker to create a malicious block with invalid transactions in it offline and then upload it to the network when the time was appropriate for an attack.
                      </p>
                      <p>
                        In our model, it is impossible to pre-compute the timestamp value, because it’s a truly random NIST beacon value and a new one is generated every 60 seconds. Also, for a block to carry the NIST timestamp means that the block must have been generated following the release of the beacon. We can thus be certain that an “offline chain attack” as can be conducted on all other cryptocurrencies, has not taken place.{' '}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="section6" className="bg-white skipScroll">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 ">
                <div className="">
                  <div className="">
                    <div className="boxedblueXX mb-4">
                      <h6 className="upper line text-grey mb-4">Technical</h6>
                      <h1 className="display-4 font-weight-bold mb-4 text-dark-blue">
                        Read The temtum White Paper
                      </h1>
                      <p className="lead">
                        Our whitepaper is now available for review, with details on our Temporal blockchain, temtum, cryptography, team, road map and the upcoming ICO tokenomics and sale.
                      </p>
                      <Link to="/whitepaper" className="btn btn-outline-secondary scroll">
                        Read White Paper
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 ">
                <div className="">
                  <div className="">
                    <div className="boxedblueXX mb-4">
                      <h6 className="upper line blue text-grey mb-4">
                        Research
                      </h6>
                      <h1 className="display-4 font-weight-bold mb-4 text-dark-blue">
                        Read Our Research Papers
                      </h1>
                      <p className="lead">
                        We've spent the past 5 years researching Blockchain and boast the youngest Blockchain PhD in the UK as part of our founding team. Richard is a PhD candidate with an approved Thesis and a PhD a few months away.
                      </p>
                      <Link to="/research" className="btn btn-outline-primary scroll">
                        View Research Papers
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </Layout>
)

export default Beacon
